import React, {useState} from "react";
import styled from 'styled-components'
import { createGlobalStyle } from 'styled-components'
import './components/Wheel'
import Wheel from './components/Wheel';
import List from './components/List';
import * as tokens from "./tokens";
import './fonts/fonts.css';

const GlobalStyle = createGlobalStyle`
  body {
    font-family: ${tokens.font.bold}
    background: ${tokens.color.background};
    color: ${tokens.color.text};
    text-align: center;
  }
  svg g{
    cursor: pointer;
  }
`

const StyledApp = styled.div`
  max-width: 640px;
  margin: 40px auto;
`

const StyledIntro = styled.p`
  opacity: 0.5;
  font-family: ${tokens.font.bold};
  font-weight: normal;
`

function App() {  
  const [options, setOptions] = useState(['Seinfeld', 'Friends', 'The Office', 'Black Books', 'The Simpsons', 'Red Dwarf']);
  const [newOption, setNewOption] = useState([]); 
  const [spinning, setSpinning] = useState(false);
  const [winner, setWinner] = useState();
  const [winners, setWinners] = useState([]);

  const removeOption = (index) => {
    if(!spinning){
      setOptions( (prevState) => 
        prevState.filter( (item) => item !== options[index])
      );
    }
  }

  const handleSetWinner = (winner) => {
    setWinner(winner);
    if(winner !== undefined){
      setWinners( [winner, ...winners]); // updating hisotry of winners
    }
  }

  return (
    <React.Fragment >
      <GlobalStyle/>
      <StyledApp>
          <Wheel
            winner={winner}
            history={winners}
            setWinner={ (winner) => handleSetWinner(winner) }
            newOption={newOption}
            options={options}
            removeOption={ index => removeOption(index) }
            spinning={spinning}
            setSpinning={setSpinning}
          />

          <StyledIntro>Type to add options. Hit ENTER to spin. Hit Backspace to delete.</StyledIntro>
          
          <List 
            options={options}
            removeOption={ (i) => removeOption(i) }
            spinning={spinning}
            setSpinning={setSpinning}
            winner={winner}
            setWinner={ (winner) => handleSetWinner(winner) }
            onChange={ text => setNewOption(text.trim()) } 
            onSubmit={ text => setOptions([ ...options, text]) }/>

      </StyledApp>
    </React.Fragment>
  );
}

export default App;
