
export const vividTheme = {
    dark: '#101318',
    light: '#E9EAEA',
    purple: '#B6A2EB',
    green: '#5CBC62',
    orange: '#E56838',
    yellow: '#FFC266',
    pink: '#E366CE', 
    teal: '#38C6E5',
}

export const palette = [vividTheme.purple, vividTheme.green, vividTheme.orange, vividTheme.yellow, vividTheme.pink, vividTheme.teal];

export const color = {
    background: vividTheme.dark,
    text: vividTheme.light,
}

export const font = {
    body: "'open_sauce_two-bold', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;",
    bold: "'open_sauce_two-black', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;",
}
