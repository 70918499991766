import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import * as tokens from "../tokens";
import FocusLock from 'react-focus-lock';

const useInputValue = (initialValue) => {
    const [value, setValue] = useState(initialValue);
    return {
        value,
        onChange: (e) => setValue(e.target.value),
        resetValue: () => setValue(""),
    };
};


const StyledInput = styled.input`
    background: black;
    border: 0px;
    color: white;
    padding: 16px 24px;
    font-family: ${tokens.font.body};
    font-size: 24px;
    opacity: 0;
`


const List =  props  => {
    const {resetValue, ...text} = useInputValue("");
    const inputRef = useRef();

    useEffect( () => {
        if(!props.spinning) inputRef.current.focus();
    },[props.spinning, props.options] );
    
    const onKeyDown = (e) => {
        if (e.key === 'Backspace' && text.value === '') { // on delete
            if(props.winner){
                props.setWinner(undefined)
            }else{
                props.removeOption(props.options.length-1)
            }
        }
    }

    const handleInputBlur = (e) => {
        //on losing focus
        inputRef.current.focus();
    }

    return (
        <form onChange={ props.onChange(text.value) }
        
        onSubmit={ e => {                
                e.preventDefault();
                if(text.value.trim() === ''){
                    props.winner ? props.setWinner(undefined) : props.setSpinning(true); 
                }else{
                    props.setWinner(undefined);
                    props.onSubmit(text.value.trim());
                    resetValue();
                }
            }
            }>
            
            <FocusLock returnFocus={true} >
                <StyledInput 
                onKeyDown={ (e) => onKeyDown(e, text.value) } 
                ref={inputRef} 
                autoFocus 
                {...text} 
                disabled={props.spinning}
                onBlur={handleInputBlur}
                />
            </FocusLock>
            
            {/* {props.options.map( (text, i) => (
                <div key={i} onClick={ () => props.removeOption(i)}>{text}</div>
            ))} */}

        </form>
    );
}

export default List;
