import React, { useEffect,useRef } from 'react'
import useState from 'react-usestateref';
import styled from 'styled-components';
import useSound from 'use-sound';
import click from '../sounds/click.mp3';
import tada from '../sounds/tada.wav';
import * as tokens from '../tokens';

const StyledOutputText = styled.p`
  font-size: 32px;
  font-family: ${tokens.font.bold}
  background: ${tokens.color.background};
  color: ${tokens.color.text};
`

const Wheel = props => {
  const [selected, setSelected] = useState('😵‍💫 Hit ENTER to spin! ');
  const optionRef = useRef([]);
  optionRef.current = [];
  var updatedOptions = [...props.options];
  if( props.newOption.length > 0){
    updatedOptions = [...props.options, props.newOption];
  }

  const [playTada] = useSound(tada);
  const [playClick] = useSound(click);

  // drawing the wheel
  const nrOptions =  updatedOptions.length;
  const perc = 100 / nrOptions;
  const r = 200;
  const viewBox = "0 00 "+ + r*2 + " " + r*2;
  const c = 2 * Math.PI * r;
  const sliceStroke = (perc/2 * c / 100) + " " + c;
  const offset = 270;
  
  // spinning the wheel
  const [rotation, setRotation, rotationRef] = useState(0);
  const timeRef = React.useRef();
  var toRotate = 3000;
  const duration = 500;
  var interval = 0;
  var transformation = "rotate(" + ( rotation + offset ) + " " + r + " " + r + ")";
  
  // Easing functions
  // https://www.kirupa.com/js/easing.js
  function easeOutCubic(currentIteration,startValue,changeInValue,totalIterations){
    return changeInValue*(Math.pow(currentIteration/totalIterations-1,3)+1)+startValue;
  }      

  useEffect( () => {
    playClick();
  },[selected]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // spin when hit enter on empty input
    if(props.spinning){
      spinWheel(!props.spinning);
    }
  },[props.spinning]) // eslint-disable-line react-hooks/exhaustive-deps
 
  // adding new option
  useEffect(() => {
    resetHighlight();
    setRotation( 0 );
  },[props.newOption, props.options, setRotation])
  
  useEffect(() => {
    if(props.winner !== undefined){
      highlightWinner(getHit());
    }else{
      resetHighlight();
    }
  },[props.winner]) // eslint-disable-line react-hooks/exhaustive-deps

  const getHit = () => {
    let getRelativeRotation = ((rotationRef.current) % 360);
    let hit =  ( getRelativeRotation / (360 / nrOptions) ); 
    return hit = nrOptions - Math.ceil(hit); // rotation in clockwise direction (-> subtract)
  }

  const spinWheel = (spinning) => {
    if(!spinning){
      console.log('History:',props.history)
      props.setSpinning(true);
      props.setWinner(undefined);
      resetHighlight();
      toRotate += (Math.random() * 360);
      // console.log("toRotate: ", toRotate);
      timeRef.current = requestAnimationFrame(animate);
    }else{
      console.log("Already spinning...");
    }
  }

  //
  // Animate the rotation
  //
  const animate = (timestamp) => {

    if (interval === duration){
      // highlightWinner(getHit());
      playTada();
      props.setWinner( props.options[getHit()]);
      props.setSpinning(false);
      return () => cancelAnimationFrame(timeRef.current);
    }

    setSelected( props.options[getHit()] );
    setRotation( easeOutCubic(interval, rotation, toRotate, duration) );
    interval++;
    timeRef.current = requestAnimationFrame(animate);

  }


  
  const addToRefs = (el) => {
    if(el && !optionRef.current.includes(el)){
      optionRef.current.push(el);
    }
  }
  
  const resetHighlight = () => {
    optionRef.current.forEach(element => {
      if (element.style ){
        element.style.opacity = '1';
      }
    });
  }

  const highlightWinner = (option) => {
    let toHighlight = optionRef.current[option];
    if ( toHighlight !== undefined){
      optionRef.current.forEach( (element, index) => {
        if ( index === option ){
          element.style.opacity = '1';
        }else{
          element.style.opacity = '0.1';
        }
      });
    }
  }
   
  const getColor = (index) => {
    let short = tokens.palette.slice(0, -1);
    let colorIndex = index % short.length;

    if(props.newOption.length > 0 && index === nrOptions-1){
      return 'white';
    }
    if( colorIndex === 0 && index > 0){
      return tokens.palette[tokens.palette.length-1];
    }else{
      return short[ (index) % short.length ];
    }
  }

  const handleHover = (e) => {
    // the logic is too stupid -> Todo: reset the hover state on spin
    // if(!props.spinning && props.winner == undefined){
    //   let s = e.target.style;
    //   s.opacity == 0.5 ? s.opacity = 1 : s.opacity = 0.5;
    // }
  }

  const getTextRotation = () => {
    let rotation = 180 / nrOptions;
    let originX = r*1.5 - nrOptions;
    let originY = r + nrOptions;
    if(nrOptions <=3 ){
      originX = r;
      originY = r;
    }
    return "rotate(" + rotation + "," + originX + "," + originY + ")"
  }

  const getTextPosition = (coordinate) => {
    if( coordinate === 'x'){
      if (nrOptions <= 3 ) return r* 1.9;
      if( nrOptions === 4 || nrOptions === 5 ) return r * 2;
      return r * 1.95 ;
    }
    if( coordinate === 'y'){
      if( nrOptions <=3 ) return r+5;
      if( nrOptions === 4 ) return r + 0.85*( 360 / (nrOptions));
      return r + 0.91*( 360 / (nrOptions));
    }
  }

  const deleteSlice = (index) => {
      if(props.winner){
        props.setWinner(undefined);
      }else{
        props.removeOption(index)
      }
  }

  return (
    <>
      <svg transform="rotate(90)" viewBox={viewBox}>
      
        <defs>
          <linearGradient gradientUnits="userSpaceOnUse" x1={r} x2={r+50} y1="0" y2="0" id="myGradient">
            <stop offset="5%" stopOpacity="0" />
            <stop offset="95%" stopOpacity="1" />
          </linearGradient>
        </defs>

        <g transform={transformation} >

          {updatedOptions.map( (element, index) => (

            <g key={index} ref={addToRefs} 
              onClick={ () => deleteSlice(index) }
              onMouseOver={ handleHover }
              onMouseOut={ handleHover }
              transform={"rotate(" + (index * (360/nrOptions)) + " " + r + " " + r + ")"}>
              <circle r={r/2} cx={r} cy={r} fill="none"
              stroke={ getColor(index) }
              strokeWidth={r}
              strokeDasharray={sliceStroke} 
              />
              <text 
                 letterSpacing="-0.8" fill="url('#myGradient')" textAnchor="end"
                transform={getTextRotation()} 
                x={ getTextPosition('x') } y={ getTextPosition('y') } >{updatedOptions[index]}</text>
            </g>
            ) )}
            
        </g>

        <polygon points="0,0 40,0 20,40"
        transform={"translate(" + (r-20) +")"}
        fill="black"/>

      </svg>

      {/* <button onClick={ () => spinWheel(props.spinning) }>Take me for a spin</button> */}
      
      <StyledOutputText>{props.winner ? props.winner : selected }</StyledOutputText> 

    </>
    
  );
}

export default Wheel;
